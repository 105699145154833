"use strict";
import "@materializecss/materialize";

// scrollspy for desktop menu
document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".scrollspy");
  var instances = M.ScrollSpy.init(elems, {
    offsetTop: 400,
  });
});

// mobile navigation
document.addEventListener('DOMContentLoaded', function () {
  var elems = document.querySelectorAll('.sidenav');
  var instances = M.Sidenav.init(elems);
});

// material boxed
document.addEventListener('DOMContentLoaded', function () {
  var elems = document.querySelectorAll('.materialboxed');
  var instances = M.Materialbox.init(elems);
});

document.addEventListener('DOMContentLoaded', function () {
  var videoWrappers = document.querySelectorAll('.video_wrapper');

  if (videoWrappers.length > 0) {
    videoWrappers.forEach(function (wrapper) {
      var trigger = wrapper.querySelector('.video_trigger button');

      trigger.addEventListener('click', function () {
        var parent = this.parentNode;
        parent.style.display = 'none';

        var videoLayer = parent.nextElementSibling;
        videoLayer.style.display = 'block';

        var iframe = videoLayer.querySelector('iframe');
        var dataSource = parent.getAttribute('data-source');
        var dataOptions = parent.getAttribute('data-options');
        var src = 'https://www.youtube-nocookie.com/embed/' + dataSource + '?' + dataOptions;
        iframe.setAttribute('src', src);
      });
    });
  }
});


